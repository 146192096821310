const MapPinIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7467 5.63317C13.0467 2.55317 10.3601 1.1665 8.00006 1.1665C8.00006 1.1665 8.00006 1.1665 7.9934 1.1665C5.64006 1.1665 2.94673 2.5465 2.24673 5.6265C1.46673 9.0665 3.5734 11.9798 5.48006 13.8132C6.18673 14.4932 7.0934 14.8332 8.00006 14.8332C8.90673 14.8332 9.8134 14.4932 10.5134 13.8132C12.4201 11.9798 14.5267 9.07317 13.7467 5.63317ZM8.00006 8.97317C6.84006 8.97317 5.90006 8.03317 5.90006 6.87317C5.90006 5.71317 6.84006 4.77317 8.00006 4.77317C9.16006 4.77317 10.1001 5.71317 10.1001 6.87317C10.1001 8.03317 9.16006 8.97317 8.00006 8.97317Z"
      fill="currentColor"
    />
  </svg>
);

export default MapPinIcon;
