import React from "react";
import { IconType } from "@/types/utils";

const StarIcon: IconType = ({ size, ...otherProps }) => (
  <svg
    width={size || "18"}
    height={size || "18"}
    {...otherProps}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2977 2.63297L11.6177 5.27297C11.7977 5.64047 12.2777 5.99297 12.6827 6.06047L15.0752 6.45797C16.6052 6.71297 16.9652 7.82297 15.8627 8.91797L14.0027 10.778C13.6877 11.093 13.5152 11.7005 13.6127 12.1355L14.1452 14.438C14.5652 16.2605 13.5977 16.9655 11.9852 16.013L9.74268 14.6855C9.33768 14.4455 8.67018 14.4455 8.25768 14.6855L6.01518 16.013C4.41018 16.9655 3.43518 16.253 3.85518 14.438L4.38768 12.1355C4.48518 11.7005 4.31268 11.093 3.99768 10.778L2.13768 8.91797C1.04268 7.82297 1.39518 6.71297 2.92518 6.45797L5.31768 6.06047C5.71518 5.99297 6.19518 5.64047 6.37518 5.27297L7.69518 2.63297C8.41518 1.20047 9.58518 1.20047 10.2977 2.63297Z"
      fill="#F19204"
    />
  </svg>
);

export default StarIcon;
