const HeartLightIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.39098 11.5983C0.999574 7.5 3.49952 4.13839 6.28364 3.28632C8.99952 2.45515 10.9996 3.30167 11.9995 4.5C12.9996 3.30167 14.9995 2.45842 17.7048 3.28632C20.6704 4.1939 22.9995 7.5 21.6069 11.5983C19.849 16.9083 12.9996 20.9983 11.9995 20.9983C10.9994 20.9984 4.20784 16.9703 2.39098 11.5983Z"
      stroke="white"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.39098 11.5983C0.999574 7.5 3.49952 4.13839 6.28364 3.28632C8.99952 2.45515 10.9996 3.30167 11.9995 4.5C12.9996 3.30167 14.9995 2.45842 17.7048 3.28632C20.6704 4.1939 22.9995 7.5 21.6069 11.5983C19.849 16.9083 12.9996 20.9983 11.9995 20.9983C10.9994 20.9984 4.20784 16.9703 2.39098 11.5983Z"
      stroke="white"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HeartLightIcon;
