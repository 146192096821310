const DiscountIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2606 3.52346C15.526 2.92707 14.474 2.92707 13.7394 3.52346L11.5874 5.27062C11.2858 5.5154 10.9196 5.66711 10.5333 5.70722L7.77618 5.9935C6.83504 6.09122 6.09116 6.8351 5.99344 7.77625L5.70715 10.5334C5.66704 10.9197 5.51534 11.2859 5.27056 11.5874L3.5234 13.7394C2.92701 14.474 2.92701 15.526 3.5234 16.2606L5.27056 18.4126C5.51534 18.7141 5.66704 19.0804 5.70715 19.4667L5.99344 22.2238C6.09116 23.165 6.83504 23.9088 7.77618 24.0066L10.5333 24.2928C10.9196 24.333 11.2858 24.4847 11.5874 24.7294L13.7394 26.4766C14.474 27.073 15.526 27.073 16.2606 26.4766L18.4126 24.7294C18.7141 24.4847 19.0803 24.333 19.4666 24.2928L22.2238 24.0066C23.1649 23.9088 23.9088 23.165 24.0065 22.2238L24.2928 19.4667C24.3329 19.0804 24.4846 18.7141 24.7294 18.4126L26.4765 16.2606C27.0729 15.526 27.0729 14.474 26.4765 13.7394L24.7294 11.5874C24.4846 11.2859 24.3329 10.9197 24.2928 10.5334L24.0065 7.77625C23.9088 6.8351 23.1649 6.09122 22.2237 5.9935L19.4666 5.70722C19.0803 5.66711 18.7141 5.5154 18.4126 5.27062L16.2606 3.52346ZM11.8687 19.3687L19.3687 11.8687C19.7104 11.527 19.7104 10.973 19.3687 10.6313C19.027 10.2896 18.473 10.2896 18.1312 10.6313L10.6312 18.1313C10.2895 18.473 10.2895 19.027 10.6312 19.3687C10.973 19.7105 11.527 19.7105 11.8687 19.3687ZM17.7387 18.75C17.7387 18.1977 18.1864 17.75 18.7387 17.75H18.7612C19.3135 17.75 19.7612 18.1977 19.7612 18.75C19.7612 19.3023 19.3135 19.75 18.7612 19.75H18.7387C18.1864 19.75 17.7387 19.3023 17.7387 18.75ZM11.2387 10.25C10.6864 10.25 10.2387 10.6977 10.2387 11.25C10.2387 11.8023 10.6864 12.25 11.2387 12.25H11.2612C11.8135 12.25 12.2612 11.8023 12.2612 11.25C12.2612 10.6977 11.8135 10.25 11.2612 10.25H11.2387Z"
      fill="currentColor"
    />
  </svg>
);

export default DiscountIcon;
